import React from 'react';
import HomePage from './HomePage'; // Импортируем HomePage
import './App.css'; // Импортируем стили

function App() {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
}

export default App;