import React from 'react';
import GridWaves from './GridWaves'; // Убедитесь, что путь к GridWaves правильный
import './App.css'; // Импортируем стили

const HomePage = () => {
  return (
    <div className="home-page">
      <GridWaves className="grid-waves" />
      <div className="overlay-text">
        Нейросети для вашего бизнеса
      </div>
    </div>
  );
};

export default HomePage;
