import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import './App.css';

const GridWaves = ({ className }) => {
  const mountRef = useRef(null);
  const [points, setPoints] = useState([]);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const pointCloudRef = useRef(null);

  useEffect(() => {
    // Create scene with responsive sizing
    const scene = new THREE.Scene();
    sceneRef.current = scene;

    const width = window.innerWidth;
    const height = window.innerHeight;

    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer({ 
      antialias: true, 
      alpha: true,
      powerPreference: 'high-performance'
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);
    rendererRef.current = renderer;

    mountRef.current.appendChild(renderer.domElement);

    // Create responsive grid
    const gridSize = Math.max(10, Math.min(width, height) / 100);
    const segmentsX = Math.floor(width / 50);
    const segmentsY = Math.floor(height / 50);

    const geometry = new THREE.PlaneGeometry(gridSize, gridSize, segmentsX, segmentsY);
    const material = new THREE.MeshBasicMaterial({ 
      color: 0x2563eb, 
      wireframe: true,
      transparent: true,
      opacity: 0.7
    });
    const plane = new THREE.Mesh(geometry, material);
    scene.add(plane);

    camera.position.z = gridSize / 2;

    let touchX = 0;
    let touchY = 0;

    // Responsive touch/mouse handling
    const handleInteraction = (clientX, clientY) => {
      touchX = ((clientX / width) * 2 - 1) * 2;
      touchY = -((clientY / height) * 2 - 1) * 2;
    };

    const onTouchMove = (event) => {
      const touch = event.touches[0];
      handleInteraction(touch.clientX, touch.clientY);
    };

    const onMouseMove = (event) => {
      handleInteraction(event.clientX, event.clientY);
    };

    // Add interactive points on tap/click
    const addInteractivePoint = (clientX, clientY) => {
      const raycaster = new THREE.Raycaster();
      const mouse = new THREE.Vector2(
        (clientX / width) * 2 - 1, 
        -(clientY / height) * 2 + 1
      );

      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObject(plane);

      if (intersects.length > 0) {
        const point = intersects[0].point;
        setPoints(prev => [...prev, [point.x, point.y, point.z]]);
      }
    };

    const onTouchStart = (event) => {
      const touch = event.touches[0];
      addInteractivePoint(touch.clientX, touch.clientY);
    };

    const onMouseDown = (event) => {
      addInteractivePoint(event.clientX, event.clientY);
    };

    // Responsive point cloud
    const createPointCloud = () => {
      const pointGeometry = new THREE.BufferGeometry();
      const pointMaterial = new THREE.PointsMaterial({ 
        color: 0xffffff, 
        size: Math.max(0.05, width / 1000),
        transparent: true,
        opacity: 0.8
      });

      const pointVertices = new Float32Array(points.flatMap(([x, y, z]) => [x, y, z]));
      pointGeometry.setAttribute('position', new THREE.BufferAttribute(pointVertices, 3));

      const pointCloud = new THREE.Points(pointGeometry, pointMaterial);
      pointCloudRef.current = pointCloud;
      scene.add(pointCloud);
    };

    // Animation loop with responsive wave effect
    const animate = () => {
      requestAnimationFrame(animate);

      const vertices = geometry.attributes.position.array;
      for (let i = 0; i < vertices.length; i += 3) {
        const x = vertices[i];
        const y = vertices[i + 1];
        vertices[i + 2] = Math.sin(x + touchX * 2) + Math.cos(y + touchY * 2) * 0.5;
      }

      geometry.attributes.position.needsUpdate = true;

      renderer.render(scene, camera);
    };

    // Event listeners
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('mousedown', onMouseDown);
    window.addEventListener('touchstart', onTouchStart);

    // Resize handler
    const onResize = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;

      camera.aspect = newWidth / newHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(newWidth, newHeight);
    };

    window.addEventListener('resize', onResize);

    // Initial point cloud creation
    createPointCloud();
    animate();

    // Cleanup
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('resize', onResize);
      
      mountRef.current.removeChild(renderer.domElement);
    };
  }, [points]);

  return <div ref={mountRef} className={className} />;
};

export default GridWaves;